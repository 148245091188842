<template>
	<div class="all_detail">
		<div class="detail">
			<div class="person_infor">
				<div class="login_top">
					<div class="img"> <img src="@/assets/login/flag.png"></div>
					<div class="login_title" @click="backClick">{{$t('talent')}}</div>
				</div>
				<div class="tip">
					{{$t('thePlatformStaffWillContactYouAfterAdmission')}}
				</div>
				<div class="all_list">
					<div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{$t('name')}}
						</div>
						<div class="list_item_bottom">
							<el-input v-model="name" :placeholder="$t('inputName')" type="text" clearable>
							</el-input>
						</div>
					</div>
					<div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{$t('my.sex')}}
						</div>
						<div class="list_item_bottom">
							<el-radio-group v-model="sex" fill="#E60012" text-color="#E60012">
								<el-radio :label="1">{{$t('other.man')}}</el-radio>
								<el-radio :label="2">{{$t('other.women')}}</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{$t('postType')}}
						</div>
						<div class="list_item_bottom">
							<el-select v-model="work_id" :placeholder="$t('release.pleaseSelect')" clearable>
								<el-option v-for="item in workList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
					</div>

					<div class="list_item">
						<div class="list_item_top">
							<span>*</span>WhatsApp
						</div>
						<div class="list_item_bottom">
							<el-input :placeholder="$t('inputWhatsApp')" type="text" clearable v-model="whatsapp">
							</el-input>
						</div>
					</div>
					<div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{$t('email')}}
						</div>
						<div class="list_item_bottom">
							<el-input :placeholder="$t('login.inputEmail')" type="text" clearable v-model="email">
							</el-input>
						</div>
					</div>
					<div class="list_item">
						<div class="list_item_top">
							<span>*</span>{{$t('residentialAddress')}}
						</div>
						<div class="list_item_bottom">
							<el-input :placeholder="$t('inputResidentialAddress')" type="text" clearable v-model="addr">
							</el-input>
						</div>
					</div>

					<div class="list_item" style="border-bottom: none;">
						<div class="list_item_top">
							<span>*</span>{{$t('resumeUpload')}}
						</div>
						<div class="list_item_bottom">
							<div class="all_imgs">
								<el-upload class="upload-demo" ref="uploadImg" :action="uploadImgUrl"
									:on-preview="handlePictureCardPreview" :on-remove="handleRemove"
									:before-upload="beforeUpload" :on-change="onChange" :data="{dir: 'file'}">
									<el-button size="small" type="primary">{{$t('clickUpload')}}</el-button>
									<div slot="tip" class="el-upload__tip">{{$t('onlyUploadPDFAndImg')}}</div>
								</el-upload>
							</div>
						</div>
					</div>
					<div class="btn" @click="submitClick">
						<div>{{$t('submit')}}</div>
					</div>
				</div>
			</div>
		</div>


		<div class="success_dialog">
			<el-dialog title="" :visible.sync="releaseSuccessVisible" width="30%">
				<div class="release_success">
					<div class="success_img">
						<img src="@/assets/home_icons/release_success.png">
					</div>
					<div class="success_title">
						{{$t('yourResumeHasBeenSubmittedSuccessfully')}}
					</div>
					<div class="success_tip">{{$t('thePlatformStaffWillContactYouAfterAdmission')}}</div>
				</div>
			</el-dialog>

		</div>
	</div>
</template>

<script>
	import {
		uploadImgUrl
	} from '@/common/utils/config.js';

	export default {
		data() {
			return {
				uploadImgUrl: uploadImgUrl,
				dialogImageUrl: '', //预览图片地址
				img_list: [], //上传图片列表
				previewImgDialog: false,

				name: '', //

				releaseSuccessVisible: false, //发布成功弹框

				workList: [], //职位列表
				work: '', //职位名称
				work_id: '', //职位ID
				name: '', //姓名
				sex: 1, //
				sex_name: '',
				whatsapp: '',
				email: '',
				addr: '', //居住地址

				isFlag: false,

			}
		},
		mounted() {
			this.getWorkList();
		},
		methods: {
			// 获取职位
			getWorkList() {
				this.$http.workList().then(res => {
					if (res.code == 1) {
						this.workList = res.data;
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			backClick() {
				this.$router.back();
			},
			// 图片删除
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			// 图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.previewImgDialog = true;
			},
			// 上传之前
			beforeUpload(file) {
				const fileName = file.name
				const fileType = fileName.substring(fileName.lastIndexOf('.'));
				if (
					fileType === '.jpg' ||
					fileType === '.png' ||
					fileType === '.jpeg' ||
					fileType === '.bmp' ||
					fileType === '.gif' ||
					fileType === '.pdf'
				) {

				} else {
					this.$message.error(this.$t('uploadRightFileType'));
					return false;
				}
			},
			// 上传图片成功
			onChange(file, fileList) {
				console.log(file);
				console.log(fileList);

				this.img_list = fileList;

			},
			// 提交
			submitClick() {
				if (this.isFlag) return;

				let image = this.img_list.map(item => {
					if (Object.prototype.hasOwnProperty.call(item, "response")) {
						if (item.response.code == 1) {
							return item.response.data[0].id;
						}
					}
				}).join(',');

				if (!this.name) {
					this.$message.error(this.$t('inputName'));
					return;
				}
				if (!this.sex) {
					this.$message.error(this.$t('my.selectSex'));
					return;
				}
				if (!this.whatsapp) {
					this.$message.error(this.$t('inputWhatsApp'));
					return;
				}
				if (!this.email) {
					this.$message.error(this.$t('login.inputEmail'));
					return;
				}


				if (!this.addr) {
					this.$message.error(this.$t('inputResidentialAddress'));
					return;
				}

				if (!image) {
					this.$message.error(this.$t('pleaseUploadResume'));
					return;
				}

				this.isFlag = true;

				let data = {
					name: this.name, //姓名
					sex: this.sex, //
					whatsapp: this.whatsapp,
					email: this.email,
					addr: this.addr,
					work_id: this.work_id,
					image,
				}
				// 
				this.$http.addUserWork(data).then(res => {
					if (res.code == 1) {
						this.releaseSuccessVisible = true;
						this.isFlag = false;
						this.clearData();
					} else {
						this.isFlag = false;
						this.$message.error(res.msg);
					}
				})
			},
			// 清除数据
			clearData() {
				this.name = '';
				this.sex = 1;
				this.work_id = '';
				this.whatsapp = '';
				this.email = '';
				this.addr = '';
				this.$refs.uploadImg.clearFiles();
			},
		}
	}
</script>

<style scoped="" lang="less">
	.tip {
		box-sizing: border-box;
		margin: 32px 0 0 50px;
		width: 830px;
		height: 48px;
		line-height: 48px;
		background: rgba(230, 0, 18, 0.09);
		font-size: 14px;
		padding-left: 40px;
		text-align: left;
		color: #E60012;
	}

	.all_detail {
		background: #F6F7F9;
		padding: 24px 0 64px;

	}

	.detail {
		box-sizing: border-box;
		width: 1200px;
		margin: 0 auto;
		min-height: 600px;
		background-color: #FFFFFF;
	}

	.person_infor {
		width: 1200px;
	}


	.login_top {
		display: flex;
		align-items: center;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;


		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;

			&:hover {
				cursor: pointer;
			}
		}

		.front_img {
			display: flex;
			align-items: center;
			margin: 0 12px;

			img {
				width: 8px;
				height: 14px;
			}
		}
	}

	.all_list {
		margin: 0 50px;
		padding-top: 24px;
		padding-bottom: 60px;
	}

	.list_item {
		width: 824px;
		text-align: left;
		padding: 24px 0;
		border-bottom: 1px solid #F3F5F6;
		font-size: 16px;


		&:last-child {
			border-bottom: none;
		}

		.list_item_top {
			span {
				color: #E60012;
			}
		}


		.list_item_bottom {

			margin-top: 28px;
			color: #4177DD;

			/deep/ .el-input__inner {
				width: 400px;
				border: none !important;
			}
		}
	}

	.btn {
		cursor: pointer;
		margin-top: 74px;
		text-align: left;

		div {
			text-align: center;
			display: inline-block;
			min-width: 280px;
			padding: 14px 0;
			background: #E60012;
			opacity: 1;
			border-radius: 24px;
			font-size: 16px;
			color: #FFFFFF;
		}

	}

	.success_dialog {
		/deep/ .el-dialog__body {
			max-height: 436px;
			overflow-y: hidden;
		}
	}

	.release_success {
		padding-top: 56px;
		height: 416px;
		box-sizing: border-box;


		.success_img {
			img {
				width: 216px;
				height: 120px;
			}
		}

		.success_title {
			margin: 32px 0;
			font-weight: bold;
			color: #333333;
			font-size: 20px;
		}



		.success_tip {
			margin: 0 auto;
			color: #666666;
			width: 404px;
			word-break: break-all;
		}
	}
</style>
